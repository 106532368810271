import {
  CatalogPageURLBooleanFilters,
  CatalogPageURLFilters,
  ElasticSearch,
  ElasticSearchFilterKeys,
  ElasticSearchPriceFilters,
} from '@interfaces/models/elasticSearch';
import { CatalogPageMetadataResponse } from '@interfaces/api/responses/catalog-page-metadata-response';
import {
  catalogURLParamToFiltersRename,
  filtersToCatalogURLParamRename,
} from '@maps/catalog/catalog-url-params-filters-maps';
import { catalogURLParamToSortByRename, sortByToCatalogURLParamRename } from '@maps/catalog/catalog-url-sort-by-map';
import { ElasticSearchFacet, ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { filterToFacetRename } from '@maps/catalog/search-product-facets-filters-maps';
import { AvailableCountryISOCodes } from '@interfaces/models/country';
import { availableUrlFilterKeys, catalogUrlBooleanFilters } from '@constants/catalog';
import { MySizes as Sizes, SizeCategory } from '@interfaces/models/mySizes';
import { ContentsMarketingFilter } from '@interfaces/models/contentsMarketingFilter';
import { isCatalogPage, isDirectShippingPage, isExpressDeliveryPage, isNewInPage } from '@helpers/routing';
import { lowercaseFirstLetter } from '@helpers/utils/general';

const getQueryParameter = <T>(URLSearchParams: URLSearchParams, name: string, fallback: T): T => {
  return (URLSearchParams.has(name) ? URLSearchParams.get(name) : fallback) as T;
};

const parsePageNumberFromPathname = (pathname: string): number => {
  const pathMatch = pathname.match(/\/p-(\d+)\//);
  return pathMatch ? Number(pathMatch[1]) : 1;
};

export const getUpdatedCountriesFromFacets = (
  countries: string[],
  facets: ElasticSearchResponse['facets']['fields'],
) => {
  const facetCountries = facets?.country?.map((f) => f.id) ?? [];
  return countries.filter((country) => facetCountries.includes(country));
};

// Parses campaign id from following pattern /c/campaign-1234/p-2
export const getCampaignIdFromUrl = (url: string): string => {
  // If we're not on campaign pages, no need to parse
  if (!url.includes('/c/')) {
    return null;
  }
  const [, , campaignSlug] = url.split('/');

  if (!campaignSlug) {
    return null;
  }

  const hasCampaignId = campaignSlug.match(/\d+$/);
  if (!hasCampaignId) {
    return null;
  }

  const [campaignId] = campaignSlug.match(/\d+$/);
  return campaignId;
};

export const parseURLHashes = (
  url: string,
  marketingFilter?: ContentsMarketingFilter[],
): { filters: ElasticSearch['filters']; pageIndex: number } => {
  // Host doesn't actually matter for us, so it can be anything we need
  const fullUrl: string = url?.startsWith('http') ? url : `https://www.vestiairecollective.com${url}`;
  const { pathname, hash } = new URL(fullUrl);

  const pathnameSegments: string[] = pathname.split('/').filter((path: string) => !!path);

  const catalogLinksWithoutLanguage: string = `/${pathnameSegments.filter((p) => !p.startsWith('p-')).join('/')}/`;
  const campaignId = getCampaignIdFromUrl(pathname);

  const filters: ElasticSearch['filters'] = {
    ...(campaignId && { 'campaign.id': [campaignId] }),
    // If we're on campaign, search, direct shipping or express delivery page, we don't include 'catalogLinksWithoutLanguage'
    ...(!campaignId &&
      !isCatalogPage(pathname) && {
        catalogLinksWithoutLanguage: [catalogLinksWithoutLanguage],
      }),
  };

  // get page index
  const pageIndex = parsePageNumberFromPathname(pathname);

  // Direct shipping and express delivery filters can also be accessed on special urls, we check for that in here
  if (isCatalogPage(pathname)) {
    if (isExpressDeliveryPage(pathname)) {
      filters.stock = true;
    }
    if (isDirectShippingPage(pathname)) {
      filters.directShippingEligible = true;
      filters.directShippingCountries = true;
    }
  }

  // We substring to remove initial # character
  const joinedFilters: string[] = (hash.at(0) === '#' ? hash.substring(1) : hash).split('_');

  // We loop through all filters in the url and return an array of objects {filterType: filterValue}
  joinedFilters.forEach((filter) => {
    const [parsedFilterType, selectedFilters] = filter.split('=') as [CatalogPageURLFilters, string];

    // Ensures proper redirection when navigating from notifications / alerts page (TCRM-4040)
    const filterType = lowercaseFirstLetter(parsedFilterType) as CatalogPageURLFilters;

    // If filter type is incorrect, skip it
    if (!availableUrlFilterKeys.includes(filterType)) {
      return;
    }

    if (filterType === 'priceMin' || filterType === 'priceMax') {
      const price: ElasticSearchPriceFilters['price'] = filters.price ?? {};
      if (filterType === 'priceMin') {
        price['>='] = parseInt(selectedFilters);
      }
      if (filterType === 'priceMax') {
        price['<='] = parseInt(selectedFilters);
      }
      filters.price = price;
      return;
    }

    if (filterType === 'discount') {
      filters.discount = selectedFilters.split('-').map((e) => `${e.replace(/%25/g, '%')}`);
      return;
    }

    if (filterType === 'dsEligibleCountries') {
      filters.directShippingCountries = true;
      return;
    }

    if (filterType === 'localCountries') {
      // TODO: Fix types
      // @ts-ignore
      filters.localCountries = true;
      return;
    }

    // handle localCountries
    if (filterType === 'country') {
      const regionCountries = marketingFilter?.find(
        (marketingFilter) => marketingFilter?.filterType === 'sustainability',
      )?.attributes?.countries;
      const filterCountries = selectedFilters.split('-');
      const sameAsRegion =
        regionCountries?.length === filterCountries?.length &&
        filterCountries?.every((value) => regionCountries?.includes(value));

      if (sameAsRegion) {
        delete filters.country;
        // @ts-ignore
        filters.localCountries = true;
        return;
      }
    }

    // Some filters need to be renamed from url -> filters object
    const filterName: keyof ElasticSearch['filters'] = catalogURLParamToFiltersRename(filterType);

    // Some browsers encode the values, this line normalizes this behaviour between them
    const filterValue = selectedFilters
      .split('-')
      .map((filter) => `${decodeURIComponent(filter.replace(/%25/g, '%'))}`);

    //handle sold filter, we only allow the "hide sold products" filter
    if (filterName === 'sold') {
      filters[filterName] = filterValue.filter((value) => value === '0');
      return;
    }

    // TODO: Fix the types here
    if (catalogUrlBooleanFilters.includes(filterType as CatalogPageURLBooleanFilters)) {
      // This is always a '1' or a '0'
      // @ts-ignore
      filters[filterName] = Boolean(filterValue);
    } else if (filterName.endsWith('.id')) {
      // matching everything after last # character
      // @ts-ignore
      const values = filterValue.map((value) => value.split('#').at(-1)).filter((a) => !isNaN(a));
      if (values.length > 0) {
        // @ts-ignore
        filters[filterName] = values;
      }
    } else if (filterName === 'country' || filterName === 'editorPicks') {
      const values = filterValue.map((value) => value.split('#').at(-1));
      if (values.length > 0) {
        filters[filterName] = values;
      }
    } else {
      // @ts-ignore
      filters[filterName] = filterValue;
    }
  });

  return {
    filters,
    pageIndex,
  };
};

export const generateURLHashes = (
  url: string,
  filters: ElasticSearch['filters'],
  currentPage: number,
  isoCode: AvailableCountryISOCodes,
  facets: ElasticSearchResponse['facets']['fields'],
  sortBy: ElasticSearch['sortBy'],
): string => {
  const { origin, pathname, searchParams } = new URL(url);
  const basePathName = pathname
    .split('/')
    .filter((s) => !s.startsWith('p-'))
    .join('/');
  // create an object which holds final data displayed in URL hashes
  const mappedFilters: Partial<{
    [key in CatalogPageURLFilters]: string | number;
  }> = {};
  const hiddenKeys: ElasticSearchFilterKeys[] = ['catalogLinksWithoutLanguage', 'directShippingCountries'];
  const filterKeys = Object.keys(filters ?? {}).filter(
    (filterKey: ElasticSearchFilterKeys) => !hiddenKeys.includes(filterKey),
  );

  filterKeys.forEach((filterKey) => {
    const facetName = filterToFacetRename(filterKey as ElasticSearchFilterKeys);
    const catalogURLParamName = filtersToCatalogURLParamRename(filterKey as ElasticSearchFilterKeys);

    if (filterKey === 'price') {
      if (filters.price.hasOwnProperty('>=')) {
        mappedFilters.priceMin = filters[filterKey]['>='];
      }
      if (filters.price.hasOwnProperty('<=')) {
        mappedFilters.priceMax = filters[filterKey]['<='];
      }
      return;
    }

    if (filterKey === 'directShippingEligible') {
      mappedFilters.dsEligible = isoCode;
      mappedFilters.dsEligibleCountries = isoCode;
      return;
    }

    if (filterKey === 'localCountries') {
      mappedFilters.localCountries = 1;
      return;
    }

    // update the country in hash with new facets, e.g. country in facets can be changed after mysizes is on
    if (filterKey === 'country' && Array.isArray(filters?.country)) {
      const countries = getUpdatedCountriesFromFacets(filters.country, facets);
      if (countries.length && countries.length !== filters.country.length) {
        mappedFilters.country = countries.join('-');
        return;
      }
    }

    //TODO: Refactor this, it should be necessary to iterate through all the facets,
    // and is not very performant...
    // handle multiple sellerBadge filters seperately to fix DSXP-643
    if (filterKey === 'sellerBadge' && Array.isArray(filters?.sellerBadge)) {
      mappedFilters.sellerBadge = filters.sellerBadge.join('-');
      return;
    }

    (facets[facetName] ?? []).forEach((item: ElasticSearchFacet) => {
      // bind category names found in query data object to category id's recieved via filters API data
      if (item.id == filters[filterKey] || typeof filters[filterKey] === 'boolean') {
        // Retrieve data as is from filters that hold boolean, string values and discount as an exception
        if (
          typeof filters[filterKey] === 'boolean' ||
          filterKey === 'discount' ||
          filterKey === 'sold' ||
          !parseInt(filters[filterKey]) ||
          filterKey.includes('size') // TODO: figure out why size is in here, it's not a boolean filter...
        ) {
          mappedFilters[catalogURLParamName] = filters[filterKey] === true ? '1' : filters[filterKey];
          return;
        }

        // TODO: not sure if we need this if block, tests pass without it...
        if (
          catalogURLParamName === 'category' &&
          filters[filterKey].length >= 1 &&
          filterKeys.includes('categoryLvl0.id')
        ) {
          // structure build to display category parent name and parent id in hashes due to support of ngx
          mappedFilters[catalogURLParamName] = [
            [[filters['categoryLvl0.id']].join('#')],
            [[item.name, item.id].join('#')],
          ].join(' > ');
          return;
        }

        // @ts-ignore
        mappedFilters[catalogURLParamName] = [item.name, item.id];
        return;
      }

      const filterValue = filters[filterKey];

      // check if there are more filters selected from the same category, join them prior to encoding
      if (Array.isArray(filterValue) && filterValue.length > 1) {
        const multipleCategoryArray = [];

        // bind multiple same category data for string values and discount (display differs from other types of filter data).
        if (filterKey === 'discount' || filterKey === 'sold' || filterKey.includes('size')) {
          multipleCategoryArray.push([filterValue.join('-')]);
          // @ts-ignore
          mappedFilters[catalogURLParamName] = [multipleCategoryArray];
          return;
        }

        filterValue.forEach((id: string) => {
          facets[facetName].map((item: ElasticSearchFacet) =>
            item.id === id ? multipleCategoryArray.push([[item.name, item.id].join('#')]) : [],
          );
        });
        // @ts-ignore
        mappedFilters[catalogURLParamName] = [multipleCategoryArray.join('-')];
        return;
      }
    });
  });

  const hashes = Object.keys(mappedFilters)
    .map((key) => {
      const value = mappedFilters[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        Array.isArray(value) && value.length > 1 ? value.join('#') : value,
      )}`;
    })
    .join('_');

  const newSearchParams = new URLSearchParams(searchParams);

  const defaultSortBy: ElasticSearch['sortBy'] = isNewInPage(pathname) ? 'recency' : 'relevance';

  // Append sortBy query parameter if it's NOT the default value
  if (sortBy === defaultSortBy) {
    newSearchParams.delete('sortBy');
  } else {
    newSearchParams.set('sortBy', sortByToCatalogURLParamRename(sortBy));
  }

  const newUrl = new URL(currentPage !== 1 ? `p-${currentPage}/` : '', `${origin}${basePathName}`);
  newUrl.hash = hashes;
  newUrl.search = newSearchParams.toString();

  return newUrl.toString();
};

export type SearchPreferences =
  | {
      universeIds: ElasticSearch['mySizes']['universe.id'];
      sizes: ElasticSearch['mySizes']['sizes'];
    }
  | undefined;

// Parse a search preferences for my sizes request
export const parseSearchPreferences = (preferences: Sizes): SearchPreferences => {
  // TODO investigate on more efficient parsing
  if (preferences.universe?.ids?.length === 0) {
    return;
  }
  const universeIds = preferences.universe.ids.map((e) => Number(e));
  const parseSize = (size: SizeCategory) => {
    const key = `sizes.${size.id}`;
    const values = size.sizes.map((e) => `${e.label}#${e.id}`);
    return {
      [key]: values,
    };
  };
  const sizes = {};
  preferences.size_categories.forEach((size) => Object.assign(sizes, parseSize(size)));
  return {
    universeIds,
    sizes,
  };
};

// Map current user country to proper sizeType
export const mapCountryToTheSizeType = (country: string) => {
  return ['DE', 'ES', 'FR', 'IT', 'KR', 'UK'].includes(country) ? country : 'US';
};

export const parseURLQueryParameters = (url: string) => {
  // Host doesn't actually matter for us, so it can be anything we need
  const fullUrl: string = url?.startsWith('https') ? url : `https://vestiairecollective.com${url}`;
  const { pathname, search } = new URL(fullUrl);
  const queryParameters = new URLSearchParams(search);

  const page: number = parsePageNumberFromPathname(pathname);
  const query = getQueryParameter<string | null>(queryParameters, 'q', null);
  const productId = getQueryParameter<string | null>(queryParameters, 'product_id', null);
  const sortBy =
    isNewInPage(pathname) && !queryParameters.has('sortBy')
      ? 'recency'
      : catalogURLParamToSortByRename(getQueryParameter<'0' | '1' | '2' | '3'>(queryParameters, 'sortBy', '0'));
  const campaignId = getCampaignIdFromUrl(pathname);
  // Removes "p-{number}/"
  const pathnameWithoutPagination = pathname.replace(/(p-)\d+\//, '');

  return {
    pathname,
    pathnameWithoutPagination,
    campaignId,
    productId,
    page,
    sortBy,
    query,
  };
};

export const isKidsCatalog = (metadata: CatalogPageMetadataResponse): boolean => {
  return (metadata?.alternateVersions?.find((e) => e.language === 'en')?.path ?? '').includes('kids');
};

export const getPathNameWithoutPage = () => {
  const fullUrl = window.location.href;
  const url = new URL(fullUrl);
  const pathnameSegments: string[] = url.pathname.split('/').filter((path: string) => !!path);
  return `/${pathnameSegments.filter((p) => !p.startsWith('p-')).join('/')}/`;
};
