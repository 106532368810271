import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import ProductCard from '@components/organisms/catalog/components/product-card/product-card';
import ProductCardSkeleton from '@components/organisms/catalog/components/skeleton-components/product-card-skeleton/product-card-skeleton';
import { Product } from '@interfaces/models/product';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { useAuthentication } from '@context/authentication.context';
import { useBucket } from '@context/bucket.context';
import AuthGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card-props';
import styles from './catalog-product-feed.module.scss';

type CatalogProductFeedProps = {
  products?: Array<Product | CatalogProduct>;
  isLoading?: boolean;
  containerClsx?: React.CSSProperties | string;
  productCardProps?: Omit<ProductCardProps, 'product' | 'index'>;
};

const defaultProductCardProps = {
  showProductTags: true,
  showSellerBadge: true,
  showProductSize: true,
  showProductExpressDelivery: true,
  showProductLocation: true,
  showProductDirectShipping: true,
  showDiscountPrice: true,
};

const CatalogProductFeed: React.FC<CatalogProductFeedProps> = ({
  products,
  isLoading,
  containerClsx,
  productCardProps,
}): React.JSX.Element => {
  const { t } = useTranslation();
  const { shouldDisplayAuthDialog, setShouldDisplayAuthDialog, setRedirectUrl } = useAuthentication();

  const { isFeatureEnabled } = useBucket();
  const isGuestXpPdp = isFeatureEnabled((f) => f.guestXpPdp, true);
  const productList = useMemo(() => {
    if (isLoading) {
      return Array.from(Array(5).keys()).map((key) => (
        <li
          key={key}
          className={styles['product_feed__flexContainer--item']}
        >
          <ProductCardSkeleton />
        </li>
      ));
    }

    if (!products?.length) {
      return null;
    }

    return products?.map((product, index) => (
      <li
        key={product.id}
        className={styles['product_feed__flexContainer--item']}
      >
        <ConditionalWrapper
          condition={!isGuestXpPdp && shouldDisplayAuthDialog}
          wrap={(children) => (
            <AuthGuard
              onClick={() => {
                setShouldDisplayAuthDialog(false);
                setRedirectUrl({ url: product.path, mode: 'push' });
              }}
              shouldPreventDefault={true}
              onAuth={() => (window.location.pathname = product.path)}
            >
              {children}
            </AuthGuard>
          )}
        >
          <ProductCard
            index={index}
            // TODO: fix typings to allow for CatalogProduct
            product={product}
            {...{ ...defaultProductCardProps, ...productCardProps }}
            customClass={styles.productCardContainer}
          />
        </ConditionalWrapper>
      </li>
    ));
  }, [isLoading, products, productCardProps, isGuestXpPdp]);

  return (
    <div className={clsx(styles.product_feed__column, containerClsx)}>
      {!productList ? (
        <span>{t('CATALOG.NO_RESULTS')}</span>
      ) : (
        <ul className={styles.product_feed__flexContainer}>{productList}</ul>
      )}
    </div>
  );
};

export default CatalogProductFeed;
