import React, { useRef, useState } from 'react';
import Button from '@components/atoms/button/button';
import clsx from 'clsx';
import { Swiper, SwiperProps } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
import 'swiper/css';
import useWindowSize from '@hooks/use-window-size';
import { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card-props';
import { SLIDER_BREAKPOINTS, SLIDER_CONFIG } from './product-slider-config';
import styles from './product-slider.module.scss';

export interface ProductSliderProps<T> {
  products: Array<T>;
  swiperProps?: Partial<SwiperProps>;
  productCardProps?: Partial<ProductCardProps> & { pageType: ProductCardProps['pageType'] };
  onVisibilityChange?: ({ isVisible, index }: { isVisible: boolean; index: number }) => void;
  showAuthGuard?: boolean;
  wrapperClassName?: string;
  parentBlockId?: string;
  children: React.ReactNode;
}

const ProductSlider = <T,>({
  products,
  swiperProps,
  productCardProps,
  wrapperClassName,
  children,
}: ProductSliderProps<T>) => {
  const swiperRef = useRef(null);
  const [slideProgress, setSlideProgress] = useState(0);
  const { windowSize, isWiderThanMd } = useWindowSize();
  const windowWidth = windowSize.width;

  const { cardType } = productCardProps || {};

  const hidePrevButton = slideProgress <= 0;
  const hideNextButton =
    slideProgress >= 1 ||
    (windowWidth >= SLIDER_BREAKPOINTS.tablet && products.length <= 3) ||
    (windowWidth >= SLIDER_BREAKPOINTS.desktop && products.length <= 4) ||
    (windowWidth >= SLIDER_BREAKPOINTS.largeDesktop && products.length <= 5);

  const config = SLIDER_CONFIG[cardType] || SLIDER_CONFIG.standard;

  return (
    <div className={clsx(styles.product_slider__swiper_wrap, wrapperClassName)}>
      <Swiper
        modules={[Mousewheel, FreeMode]}
        onInit={(core) => {
          swiperRef.current = core.el;
        }}
        freeMode={{
          enabled: true,
          sticky: isWiderThanMd,
          momentumBounce: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        onProgress={(e) => setSlideProgress(e.progress)}
        watchSlidesProgress={true}
        shortSwipes={false}
        draggable
        slidesPerView={config.slidesPerView}
        spaceBetween={0}
        breakpoints={{
          [String(SLIDER_BREAKPOINTS.mobile)]: {
            slidesPerView: config.mobileSlidesPerView,
            slidesPerGroup: config.mobileSlidesPerGroup || 1,
            spaceBetween: 0,
            centeredSlides: false,
          },
          [String(SLIDER_BREAKPOINTS.tablet)]: {
            slidesPerView: config.tabletSlidesPerView,
            slidesPerGroup: config.tabletSlidesPerGroup || 1,
            spaceBetween: 0,
            centeredSlides: false,
          },
          [String(SLIDER_BREAKPOINTS.desktop)]: {
            slidesPerView: config.desktopSlidesPerView,
            slidesPerGroup: config.desktopSlidesPerGroup || 1,
            spaceBetween: 0,
            centeredSlides: false,
            ...(config.desktopSpeed ? { speed: config.desktopSpeed } : {}),
          },
          [String(SLIDER_BREAKPOINTS.largeDesktop)]: {
            slidesPerView: config.largeDesktopSlidesPerView,
            slidesPerGroup: config.largeDesktopSlidesPerGroup || 1,
            spaceBetween: 0,
            centeredSlides: false,
            ...(config.largeDesktopSpeed ? { speed: config.largeDesktopSpeed } : {}),
          },
        }}
        slidesOffsetAfter={isWiderThanMd ? 0 : 20}
        slidesOffsetBefore={isWiderThanMd ? 0 : 20}
        {...swiperProps}
      >
        {children}
      </Swiper>
      <Button
        data-cy="product-slider-previous-slide-button"
        disableDefaultStyling
        className={clsx(
          'vc-d-none vc-d-md-inline-block',
          styles.product_slider__swiper_button,
          styles[`product_slider__swiper_button--${cardType}`],
          styles.product_slider__prev_button,
          hidePrevButton && styles.product_slider__swiper_button__hide,
        )}
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        {/* This span is used only to internationalize above button and is not shown on page */}
        <span hidden>click prev</span>
        <div className={clsx(styles.product_slider__prev_icon, styles.product_slider__controls_icon)} />
      </Button>
      <Button
        data-cy="product-slider-next-slide-button"
        disableDefaultStyling
        className={clsx(
          'vc-d-none vc-d-md-inline-block',
          styles.product_slider__swiper_button,
          styles[`product_slider__swiper_button--${cardType}`],
          styles.product_slider__next_button,
          hideNextButton && styles.product_slider__swiper_button__hide,
        )}
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        {/* This span is used only to internationalize above button and is not shown on page */}
        <span hidden>click next</span>
        <div className={clsx(styles.product_slider__next_icon, styles.product_slider__controls_icon)} />
      </Button>
    </div>
  );
};

export default ProductSlider;
