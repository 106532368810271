import React from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import { Product } from '@interfaces/models/product';
import dynamic from 'next/dynamic';
import { ProductCardProps } from './product-card-props';
import styles from './product-card.module.scss';

const Badge = dynamic(() => import('@components/common/badge/badge'));

// @TODO: Currently api side returns localized badge, but FE side needs to do another round of mapping to indentify if
// the badge is expert or trusted seller to render corresponding icons. Ideally, we can ask API side to return bage type
// and let FE side do the localization and icon rendering
// phrase key: 0b84df4e0b66e9d55b22eefb55ca928f in legacy
const expertSellerList = ['Verkaufsexperte', 'Experte', 'Expert', 'Esperto', 'Experto', '專家'];

// phrase key: c0405d71f21e769c787a10b9620f11ea in legacy
const trustedSellerList = [
  'Vertrauenswürdiger Verkäufer',
  'Trusted',
  'Conformidad',
  'Recommandé',
  'Affidabile',
  'De Confianza',
  'Vertrouwd',
  'Pålitlig',
  '值得信賴',
];

export const getShownBadges = (product: Product) => {
  const shouldShowExpertSellerBadge: boolean = expertSellerList.includes(product?.seller?.sellerRating?.badge);
  const shouldShowTrustedSellerBadge: boolean = trustedSellerList.includes(product?.seller?.sellerRating?.badge);
  const shouldShowOfficialStoreBadge: boolean = product?.seller && product?.seller?.isOfficialStore;

  const shouldShowBadges = shouldShowExpertSellerBadge || shouldShowTrustedSellerBadge || shouldShowOfficialStoreBadge;

  return {
    shouldShowExpertSellerBadge,
    shouldShowTrustedSellerBadge,
    shouldShowOfficialStoreBadge,
    shouldShowBadges,
  };
};

const ProductCardBadges: React.FC<Pick<ProductCardProps, 'product'>> = ({ product }) => {
  const { t } = useTranslation();
  const { shouldShowExpertSellerBadge, shouldShowTrustedSellerBadge, shouldShowOfficialStoreBadge } =
    getShownBadges(product);

  if (shouldShowOfficialStoreBadge) {
    return (
      <Badge
        type="default"
        className={clsx(
          styles.productCard__badge,
          'vc-badge vc-badge--large vc-ba  dge--sm-down vc-badge--official-store',
        )}
        badgeSize="medium"
      >
        <span className={styles.productCard__badge__text}>{t('PRODUCT.DESCRIPTION.BRAND_STORE')}</span>
      </Badge>
    );
  }
  if (shouldShowExpertSellerBadge) {
    return (
      <Badge
        type="default"
        className={clsx(styles.productCard__badge, 'vc-badge vc-badge--large vc-badge--sm-down vc-badge--expert')}
        badgeSize="medium"
      >
        <Icon
          name="badge-expert"
          className={clsx('icon', styles.productCard__badge__icon)}
        />
        <span className={styles.productCard__badge__text}>{t('PRODUCT_SNIPPET.EXPERT_SELLER')}</span>
      </Badge>
    );
  }
  if (shouldShowTrustedSellerBadge) {
    return (
      <Badge
        type="default"
        className={clsx(styles.productCard__badge, 'vc-badge vc-badge--large vc-badge--sm-down vc-badge--trusted')}
        badgeSize="medium"
      >
        <Icon
          name="badge-trusted"
          className={clsx('icon', styles.productCard__badge__icon)}
        />
        <span className={styles.productCard__badge__text}>{t('PRODUCT_SNIPPET.TRUSTED_SELLER')}</span>
      </Badge>
    );
  }
};

export default ProductCardBadges;
