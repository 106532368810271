import { useQuery } from '@tanstack/react-query';
import { Negotiation } from '@interfaces/models/negotiation/negotiation';
import { CTAProductQueryKeys, NegotiationKeys } from '@enums/react-query-keys';
import ProductService from '@services/product-service';
import logger from '@helpers/utils/logger/client';
import { useNegotiation } from '@context/negotiation.context';
import { Product } from '@interfaces/models/product';
import { NegotiationsService } from '@services/negotiations-service';
import { NegotiationInitialData } from '@interfaces/models/negotiation/negotiationInitialData';
import { User } from '@interfaces/models/user';
import SellCatalogService from '@services/sell-service';

type NegotiationQueriesParams = {
  buyerId: User['id'];
  productId: Product['id'];
  enabled: boolean;
  negotiationId: Negotiation['id'];
  onQuery: () => void;
  onSuccessCallback?: () => void;
};

export const useGetActiveNegotiation = ({
  productId,
  enabled,
}: Pick<NegotiationQueriesParams, 'productId' | 'enabled'>) => {
  const { setNegotiation } = useNegotiation();

  return useQuery<Negotiation>({
    queryKey: [NegotiationKeys.ACTIVE_NEGOTIATION, productId],
    queryFn: () => ProductService.getActiveNegotiation(productId, true),
    enabled,
    onSuccess: (res) => {
      if (res) {
        setNegotiation(res);
      }
    },
    onError: (error) => {
      logger.error(error, 'Error occurred while fetching active negotiation');
    },
    retry: false,
  });
};

export const useGetNegotiationByProductIdAndBuyerId = ({
  productId,
  enabled,
  buyerId,
}: Pick<NegotiationQueriesParams, 'buyerId' | 'productId' | 'enabled'>) => {
  const { setNegotiation } = useNegotiation();
  const queryFn = async () => NegotiationsService.listNegotiations('seller', true);
  return useQuery<Negotiation[]>({
    queryKey: [NegotiationKeys.NEGOTIATIONS_BY_PRODUCT, productId],
    queryFn,
    enabled,
    onSuccess: (res) => {
      const negotiation = res.find((n) => n.product.id === productId && !n.isClosed && n.buyer.id === buyerId);
      setNegotiation(negotiation);
    },
    onError: (error) => {
      logger.error(error, 'Error occurred while fetching active negotiation');
    },
  });
};

export const useGetNegotiationById = ({
  negotiationId,
  enabled,
  onSuccessCallback,
}: Omit<NegotiationQueriesParams, 'productId' | 'buyerId' | 'onQuery'>) => {
  const { setNegotiation, negotiation } = useNegotiation();

  return useQuery<Negotiation>({
    queryKey: [CTAProductQueryKeys.NEGOTIATION, negotiationId],
    queryFn: () => NegotiationsService.getNegotiation(negotiationId),
    onSuccess: (data: Negotiation | undefined) => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      setNegotiation(data);
    },
    onError: (error) => {
      logger.error(error, 'Server responded with an error while fetching active negotiation: ');
    },
    initialData: negotiation,
    enabled,
  });
};

export const useGetNegotiationInitialData = ({
  buyerId,
  productId,
  enabled,
}: Pick<NegotiationQueriesParams, 'buyerId' | 'productId' | 'enabled'>) => {
  const { setInitialData, setNegotiation, setInitialDataError } = useNegotiation();

  return useQuery<NegotiationInitialData>({
    retry: false,
    queryKey: [NegotiationKeys.NEGOTIATION_INITIAL_DATA, productId, buyerId],
    queryFn: () => ProductService.getNegotiationInitialData(productId, buyerId),
    onSuccess: (res) => {
      if (res) {
        setInitialData(res);
        setNegotiation(null);
        setInitialDataError(false);
      } else {
        setInitialDataError(true);
      }
    },
    onError: (error) => {
      setInitialDataError(true);
      logger.error(error, 'Server responded with an error while fetching initial information for negotiation');
    },
    enabled,
  });
};

export const useGetPriceDetails = (newPrice: number, productId: string) => {
  return useQuery({
    useErrorBoundary: false,
    queryFn: () => {
      return SellCatalogService.getPriceDetails(newPrice, productId);
    },
    queryKey: [NegotiationKeys.NEGOTIATION_YOU_EARN, productId, newPrice],
    onError: (error: Error) => {
      logger.error(`Error fetching recommended price on price-drop page : ${error}`);
    },
    select: (data) => data.data,
    enabled: !!newPrice,
  });
};
