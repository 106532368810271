import { useMemo } from 'react';
import useActiveDealQuery from '@hooks/deal/use-active-deal-query';
import { Product } from '@interfaces/models/product';

export const useProductCardTags = ({ product, showProductTags }: { product: Product; showProductTags: boolean }) => {
  const { activeDeal } = useActiveDealQuery();

  const getTagName = (tag: string) => {
    switch (tag.toLowerCase()) {
      case 'welove':
        return 'We love';
      case 'dealeligible':
        return activeDeal.active ? activeDeal.localizedName : tag;
      default:
        return tag;
    }
  };

  const tags = useMemo(() => {
    if (activeDeal?.active && (product.tags?.includes('dealEligible') || product.flags?.includes('deal-eligible'))) {
      return ['dealEligible'];
    }
    return product.tags?.filter((x) => x !== 'dealEligible');
  }, [product.tags, activeDeal, product.flags]);

  const shouldShowProductTags: boolean = showProductTags && tags?.length > 0;

  return { tags, shouldShowProductTags, getTagName };
};
