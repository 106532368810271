import React from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import ProductImage from '@components/atoms/vc-images/components/product-image';
import ProductLikeButton from '@components/atoms/product-like-button/product-like-button';
import Link from '@components/atoms/link/link';
import { getCountryNameFromCountryISOCode } from '@helpers/utils/general';
import ProductSchema from '@components/structured-schemas/product-schema';
import { ImagesService } from '@services/image-service';
import useLocalizationQuery from '@hooks/localization/use-localization';
import dynamic from 'next/dynamic';
import useActiveDealQuery from '@hooks/deal/use-active-deal-query';
import { useBucket } from '@context/bucket.context';
import { useRouter } from 'next/router';
import ProductCardFavActions from '@components/molecules/products/product-card-actions/product-card-fav-actions/product-card-fav-actions';
import useRestorePageScrollPosition from '@hooks/use-restore-page-scroll-position';
import useFeatureFlag from '@hooks/use-feature-flags';
import { NegotiationProvider } from '@context/negotiation.context';
import { useProductClickTracking } from '@hooks/product-card/use-product-click-tracking';
import { useProductCardTags } from '@hooks/product-card/use-product-card-tags';
import { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card-props';
import useUser from '@hooks/user/use-user';
import styles from './product-card.module.scss';
import ProductCardBadges, { getShownBadges } from './product-card-badges';

const ProductCardLocationIcon = dynamic(
  () => import('@components/molecules/products/product-card-location-icon/product-card-location-icon'),
);

const ProductCardTaxesApplyIcon = dynamic(
  () => import('@components/molecules/products/product-card-duties-apply-icon/product-card-duties-apply-icon'),
);

export type FavTileActionsABCtestVariations = 'control' | 'similar' | 'mmao';

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    product,
    index,
    customClass,
    pageType = 'personalized',
    moduleType,
    showSellerBadge = false,
    showDiscountPrice = false,
    showProductLocation = false,
    showProductSize = false,
    showProductTags = false,
    showProductDirectShipping = false,
    showProductExpressDelivery = false,
    showTaxesApply = false,
    isFavourite = false,
    disableLazyLoad = false,
    trackingAlerts,
    productFeedCategory,
    isNewProduct = false,
    onClick,
    containerId,
    forceImgLazyLoadingCb,
    includeProductSchema = true,
    fetchpriority = 'low',
    localCountriesApplied,
    isGoodPriceVariantEnabled = false,
    parentUid,
    parentPosition,
    disableLikeButton = false,
    cardType = 'standard',
  } = props;

  const { t } = useTranslation();
  const { countryList } = useLocalizationQuery();
  const { activeDeal } = useActiveDealQuery();
  const router = useRouter();
  const { setScrollPosition } = useRestorePageScrollPosition({});
  const { isSemPDP } = useFeatureFlag();

  const { user } = useUser();
  // TCRM-3965: Check if the user is in the same region as the product (To align with favorites payload)
  const isSameRegion =
    product.localCountries?.some((country) => country.toLowerCase() === user?.countryISO.toLowerCase()) ?? false;
  const isLocalCountry: boolean = product?.flags?.includes('local-country') || localCountriesApplied || isSameRegion;
  const productUrl = product.path;
  const isProductDirectShippable: boolean = product?.flags?.includes('direct-shipping');
  const shouldDisplaySize: boolean = showProductSize && !!product?.size?.size;
  const { shouldShowBadges } = getShownBadges(product);

  const isFavouriteView = pageType === 'favourite' || pageType === 'profile-favorites';
  const { getFeature } = useBucket();
  const { option } = router?.query as {
    option: string; // TODO: QA testing as the API doesn't return the correct payload
  };
  const favTileActionsABCtestVariations =
    (option as FavTileActionsABCtestVariations) ??
    (getFeature('enableFavoritesTileBottomActions', true)?.value as FavTileActionsABCtestVariations);

  const { productClickTracking, trackProductCrossSellClick } = useProductClickTracking({
    product,
    pageType,
    moduleType,
    parentPosition,
    index,
    parentUid,
    trackingAlerts,
    isNewProduct,
    productFeedCategory,
  });

  const { tags, shouldShowProductTags, getTagName } = useProductCardTags({
    product,
    showProductTags,
  });

  const handleClick = async (): Promise<void> => {
    productClickTracking();
    if (pageType === 'favourite' || pageType === 'profile-favorites') {
      setScrollPosition(`${pageType}__productCard__${product.id}`);
    }
    onClick?.(product, index);
  };

  const getSEOImgPath = (path: string): string => {
    return ImagesService.improveSEOProductPathImg(path, product.path);
  };

  return (
    // TODO: Error bellow should be fixed! (We need to add keyboard listeners)
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="link"
      tabIndex={0}
      id={containerId}
      onClick={trackProductCrossSellClick}
      className={clsx(styles.productCard, styles[`productCard--${cardType}`], customClass)}
      data-cy={`${pageType}__productCard__${product.id}`}
      data-vc-dd-action-name={`product_card__${pageType}`}
    >
      {includeProductSchema && <ProductSchema product={product} />}
      {/* Top container contains seller tags and like count */}
      {!isSemPDP && (
        <div
          className={clsx(
            styles.productCard__topContainer,
            !shouldShowBadges && styles['productCard__topContainer--onlyLikeButton'],
            disableLikeButton && !shouldShowBadges && 'vc-d-none',
          )}
        >
          {/* Seller tag is shown on 'recently viewed' & catalog pages */}
          {showSellerBadge && shouldShowBadges && <ProductCardBadges product={product} />}
          {/* Like button */}
          {!disableLikeButton && (
            <ProductLikeButton
              product={product}
              position={index + 1}
              showText={false}
              isFavorite={isFavourite}
              alertTracking={{
                alertTitle: trackingAlerts?.title,
                alertStatus: isNewProduct ? 'new' : '',
              }}
              pageType={pageType}
              parentPosition={parentPosition}
              {...{ moduleType, productFeedCategory }}
            />
          )}
        </div>
      )}

      {/* Product image */}
      <div
        className={clsx(styles.productCard__imageContainer, styles[`productCard__imageContainer--${cardType}`])}
        data-cy="productCard__imageContainer"
      >
        <Link
          href={productUrl}
          className={clsx(styles.productCard__image, styles[`productCard__image--${cardType}`], {
            [styles.sold]: product.sold,
          })}
          onClick={handleClick}
          data-cy="productCard__image"
          disableClientSideNavigation={pageType === 'catalog'}
        >
          <ProductImage
            src={getSEOImgPath(product.pictures[0]?.path)}
            height={256}
            width={256}
            alt={product.pictures[0]?.alt ? product.pictures[0]?.alt : product.name}
            type="snippet"
            disableLazyLoad={disableLazyLoad}
            forceImgLazyLoadingCb={forceImgLazyLoadingCb}
            fetchpriority={fetchpriority}
          />
          {product.sold && (
            <span className={styles.productCard__image__soldText}>{t('PRODUCT_SNIPPET.SOLD_CARD')}</span>
          )}
        </Link>
      </div>
      {/* Tags */}

      {shouldShowProductTags && (
        <div className={styles.productCard__tagsContainer}>
          {tags.map((tag) => (
            <span
              style={
                tag == 'dealEligible'
                  ? { color: activeDeal.textColor, background: activeDeal.backgroundColor }
                  : { background: '#f5f6f8' }
              }
              className={styles.productCard__tag}
              key={tag}
            >
              {getTagName(tag)}
            </span>
          ))}
        </div>
      )}
      {/* Product details */}
      <div
        className={clsx(
          styles.productCard__productDetails,
          !shouldShowProductTags && styles['productCard__productDetails--noProductTags'],
          styles[`productCard__productDetails--${cardType}`],
          isSemPDP && styles[`productCard__productDetails--semPdpIteration`],
        )}
        data-cy="productCard__productDetails__noProductTags"
      >
        <Link
          href={productUrl}
          onClick={handleClick}
        >
          <h2
            className={styles.productCard__productLink}
            data-cy="productCard__productLink"
          >
            {isNewProduct && <span className={styles['productCard__text--new']}>{t('ALERTS.LANDING.NEW_LABEL')}</span>}
            <span
              className={clsx(styles.productCard__text, styles['productCard__text--brand'])}
              data-cy="productCard__text__brand"
            >
              {product.brand.localizedName?.toUpperCase()}&nbsp;
            </span>

            <span
              className={clsx(
                styles.productCard__text,
                styles['productCard__text--name'],
                styles[`productCard__text--name--${cardType}`],
              )}
              data-cy="productCard__text__name"
            >
              {product.name}
              {cardType === 'small' && shouldDisplaySize && (
                <span>
                  {product?.size?.size && ','}&nbsp;
                  {product?.size?.size}&nbsp;
                  {product?.size?.localizedStandard}
                </span>
              )}
            </span>
            {shouldDisplaySize && cardType !== 'small' && (
              <p
                className={styles['productCard__text--size']}
                data-cy="productCard__text__size"
              >
                <span className="vc-d-none">{t('PRODUCT.DESCRIPTION.DETAILS.SIZE')}</span>
                <span>
                  {product.size.size}&nbsp;
                  {product.size.localizedStandard}
                </span>
              </p>
            )}
          </h2>
        </Link>
        {isSemPDP && !disableLikeButton && (
          <ProductLikeButton
            customClass={styles.productCard__productDetails__likeButton}
            product={product}
            position={index + 1}
            showText={false}
            isFavorite={isFavourite}
            alertTracking={{
              alertTitle: trackingAlerts?.title,
              alertStatus: isNewProduct ? 'new' : '',
            }}
            pageType={pageType}
            parentPosition={parentPosition}
            {...{ moduleType, productFeedCategory }}
            hideLikeCount={true}
          />
        )}
        <div
          className={clsx(styles['productCard__text--price'], styles[`productCard__text--price--${cardType}`], {
            [styles['productCard__text--price--discount']]: product?.regularPrice?.formatted && showDiscountPrice,
            [styles['productCard__text--price--discount--goodPriceVariant']]:
              product?.regularPrice?.formatted && showDiscountPrice && isGoodPriceVariantEnabled,
          })}
        >
          {product?.regularPrice?.formatted && (
            <span
              className={clsx({
                [styles['productCard__text--price--regularPrice']]: showDiscountPrice,
                [styles['productCard__text--price--regularPrice--goodPriceVariant']]:
                  showDiscountPrice && isGoodPriceVariantEnabled,
              })}
              data-cy="productCard__text__price__regularPrice"
            >
              {product?.regularPrice?.formatted}
            </span>
          )}
          {product?.price.formatted && (
            <span
              className={clsx(styles.productCard__text)}
              data-cy="productCard__text__price__discount"
            >
              {product?.price.formatted}
              {isGoodPriceVariantEnabled && !!product.discountPercentage && product.discountPercentage >= 20 && (
                <span className={styles.productCard__discountPercentage}>{`-${product.discountPercentage}%`}</span>
              )}
            </span>
          )}
        </div>
        {/* direct shipping eligibility and country of origin are shown on 'recently viewed' & catalog pages */}
        {/* TODO: Add direct shipping eligibility and country of origin */}
        {(showProductExpressDelivery || showProductDirectShipping) && (
          <div
            className={clsx(styles.productCard__text__bottom, 'vc-text-s')}
            data-cy="productCard__text__bottom"
          >
            {isProductDirectShippable && showProductDirectShipping && (
              <div className={styles.productCard__text__bottom__ds}>
                <Icon
                  name="handshake"
                  className={styles.productCard__text__bottom__icon}
                />
                <span className={styles.productCard__text__bottom__ds__label}>
                  {t('PRODUCT_SNIPPET.DIRECT_SHIPPING')}
                </span>
              </div>
            )}

            {product.inStock && !product.sold && showProductExpressDelivery && (
              <div className={styles.productCard__text__bottom__express}>
                <Icon
                  className={styles.productCard__text__bottom__icon}
                  name="express"
                />
                <span className={styles.productCard__text__bottom__express__label}>{t('DELIVERY_OPTS.EXPRESS')}</span>
              </div>
            )}
          </div>
        )}

        {showProductLocation && getCountryNameFromCountryISOCode(countryList, product.isoCountry) && (
          <div
            className={clsx(styles.productCard__text__bottom__location, 'vc-text-s')}
            data-cy="productCard__text__location"
          >
            {!showTaxesApply && <ProductCardLocationIcon isLocalCountry={isLocalCountry} />}
            <span className={styles.productCard__text__bottom__location__label}>
              {getCountryNameFromCountryISOCode(countryList, product.isoCountry)}
            </span>
            {showTaxesApply && product.hasDutiesApplied && <ProductCardTaxesApplyIcon />}
          </div>
        )}
      </div>
      {isFavouriteView &&
        favTileActionsABCtestVariations !== undefined &&
        favTileActionsABCtestVariations !== 'control' && (
          <NegotiationProvider>
            <ProductCardFavActions
              product={product}
              favTileActionsABCtestVariations={favTileActionsABCtestVariations}
            />
          </NegotiationProvider>
        )}
    </div>
  );
};

export default ProductCard;
