import { useAnalytics } from '@context/analytics.context';
import { useRouter } from 'next/router';
import useUser from '@hooks/user/use-user';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import useProfileQuery from '@hooks/profile/use-profile-query';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { Product } from '@interfaces/models/product';
import { PageType } from '@interfaces/common/common';
import { AlertWithProducts } from '@interfaces/models/alert';

interface ProductClickTrackingProps {
  product: Product;
  pageType: PageType;
  index: number;
  moduleType?: string;
  parentPosition?: string;
  parentUid?: number;
  trackingAlerts?: AlertWithProducts;
  isNewProduct?: boolean;
  productFeedCategory?: string;
}

export const useProductClickTracking = ({
  product,
  pageType,
  moduleType,
  parentPosition,
  index,
  parentUid,
  trackingAlerts,
  isNewProduct,
  productFeedCategory,
}: ProductClickTrackingProps) => {
  const { sendEvent } = useAnalytics();
  const { sendAnalyticEvent } = useAnalyticEvents('product');
  const { user } = useUser();
  const { query } = useRouter();
  const isUserFavourites = query?.favoritesUserSlug?.[0] === user?.id;
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const { isUserProfile } = useProfileQuery();

  const productClickTracking = () => {
    const event = {
      type: 'event',
      payload: {
        event: 'product_click',
        product_id: String(product?.id),
        seller_id: String(product?.seller?.id ?? ''),
        product_universe: 'universeId' in product ? String(product.universeId) : '',
        product_category: product?.category?.name ?? '',
        product_sub_category: product.subcategory?.name ?? '',
        product_brand: product?.brand?.name ?? '',
        product_unitprice_ati: String((product?.price?.cents ?? 0) / 100 ?? ''),
        product_country: product?.seller?.country ?? '',
        product_position: String(index + 1 ?? ''),
        currency: product.price?.currency ?? '',
        product_card_tag: product?.tags?.join(',') ?? '',
      },
    };
    const { type, payload } = event;

    switch (pageType) {
      case 'favourite':
        const feedCategoryType = isUserFavourites ? 'my' : 'member';

        sendEvent({
          type,
          payload: {
            ...payload,
            product_feed_category: moduleType || `${feedCategoryType}_favourites`,
          },
        });
        return;
      case 'recommendations':
        sendEvent(event);
        return;
      case 'cms_page':
      case 'homepage':
        sendEvent({
          type,
          payload: {
            ...payload,
            product_card_tag: product.tags?.join(',') ?? '',
            product_feed_category: moduleType?.replace('-', '_'),
            product_feed_position: parentPosition,
          },
        });
        if (pageType === 'homepage') {
          setHpScrollPosition(`HP_${String(parentUid)}`);
        }
        return;
      case 'alerts':
        sendEvent({
          type,
          payload: {
            ...payload,
            nb_items: trackingAlerts?.counter?.toString(),
            product_card_tag: isNewProduct ? 'new' : '',
            product_feed_category: trackingAlerts?.id ? `saved_searches_${trackingAlerts.id}` : '',
            product_unit_price_ati: ((product?.price?.cents ?? 0) / 100).toString(),
            product_position: String(product?.position),
            currency: product.price?.currency,
          },
        });
        return;
      case 'hero-pdp':
        sendEvent({
          type: 'event',
          payload: {
            ...payload,
            product_card_tag: product.tags?.join(',') ?? '',
            product_feed_category: productFeedCategory ?? '',
          },
        });
        return;
      case 'similar-products':
        sendEvent({
          type,
          payload: {
            ...payload,
            product_card_tag: product.tags?.join(',') ?? '',
            product_feed_category: 'similar_products',
          },
        });
        return;
      case 'profile-favorites':
      case 'profile-items-for-sale':
        const isProfileFavoritesPage = pageType === 'profile-favorites';
        const itemType = isUserProfile ? 'my' : 'member';
        const product_feed_category = isProfileFavoritesPage
          ? moduleType || `${itemType}_favourites`
          : `${itemType}_items_for_sale`;
        sendEvent({
          type,
          payload: {
            ...payload,
            product_feed_category,
          },
        });
      default:
        return;
    }
  };

  const trackProductCrossSellClick = () => {
    if (pageType === 'product_page' && moduleType === 'similar_products') {
      sendAnalyticEvent('similar_products', {
        label: `${index}`,
        property: product.id,
      });
    }
  };

  return { productClickTracking, trackProductCrossSellClick };
};
